<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :traningEdu.sync="traningEdu"
          :attachTeachingMaterialsInfo.sync="attachTeachingMaterialsInfo"
          :attachTrainingPictureInfo.sync="attachTrainingPictureInfo"
          :attachEduPictureInfo.sync="attachEduPictureInfo"
          :isOld="isOld"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingTraningEduDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'plan',
      traningEdu: {
        sopFireFightingTraningEduId: '',  // 소방 훈련·교육 계획/결과 일련번호
        plantCd: '',  // 사업장코드
        traninigEduDt: '',  // 훈련·교육 일시
        sopFireFightingTraningEduStepCd: '',  // 소방 훈련·교육 계획/결과 진행상태
        traninigEduName: '',  // 소방 훈련·교육 계획/결과명
        locationName: '',  // 장소
        sopFireFightingTraningEduTargetCd: '',  // 소방 훈련·교육 대상
        sopFireFightingTraningEduPracticeCd: '',  // 소방 훈련·교육 실습
        sopFireFightingTraningEduTheoryCd: '',  // 소방 훈련·교육 이론
        deptCd: '',  // 주관부서
        fireSafetyManagerId: '',  // 소방안전관리자
        fireSafetyManagerQualitClassCd: null,  // 소방안전관리자 자격구분
        fireSafetyManagerNote: '',  // 소방안전관리자 비고
        sopFireFightingTraningEduFormCd: '',  // 소방 훈련 형태
        participatingInstitutions: '',  // 참여기관
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        traningPlan: '',  // 훈련계획
        eduPlan: '',  // 교육계획
        evaluationPlanDt: '',  // 평가계획일시
        evaluationUserId: '',  // 평가자
        evaluationMethod: '',  // 평가방법
        evaluationDt: '',  // 평가일시
        educationInstructorName: '',  // 교육강사
        totalNumber: '',  // 총원
        attendNumber: '',  // 참여인원
        nonAttendNumber: '',  // 미참석
        selfTotalNumber: '',  // 자위소방대 총원
        selfLeaderId: '',  // 자위소방대 대장명
        selfLeaderPhoneNumber: '',  // 자위소방대 대장 연락처
        selfUnitLeaderNumber: '',  // 자위소방대 부대장 인원
        selfNotiContactNumber: '',  // 자위소방대 통보연락 인원
        selfInitFireExtingNumber: '',  // 자위소방대 초기소화 인원
        selfEvacuGuidNumber: '',  // 자위소방대 피난유도 인원
        selfEmergencyContactNumber: '',  // 자위소방대 비상연락 인원
        mainContent: '',  // 주요내용
        mainAchievements: '',  // 주요성과
        problem: '',  // 문제점
        supplementaryMatters: '',  // 보완사항
        actionTaken: '',  // 조치사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        users: [],
        scenarios: [],
      },
      attachTeachingMaterialsInfo: {
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_EDU_TEACHING_MATERIALS',
        taskKey: '',
      },
      attachTrainingPictureInfo: {
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_TRAINING_PICTURE',
        taskKey: '',
      },
      attachEduPictureInfo: {
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_EDU_PICTURE',
        taskKey: '',
      },
      editable: false,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingTraningEduId)
    },
    tabHeight() {
      let height = this.contentHeight - 36;
      if (height < 800) {
        height = 800;
      }
      return String(height) +'px';
    },
    tabItems() {
      let _items = [
        { name: 'plan', icon: 'info', label: '계획수립 정보', component: () => import(`${'./fireFightingTraningEduPlanTab.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({
          name: 'action', icon: 'construction', label: '평가결과 정보', component: () => import(`${'./fireFightingTraningEduActionTab.vue'}`)
        })
      }
      return _items;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingTraningEdu.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingTraningEduId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.traningEdu, _result.data)

          console.log(this.$_.clone(this.traningEdu))
          if (this.traningEdu.sopFireFightingTraningEduStepCd !== 'FFTE000001') {
            this.tab = 'action'
          }

          this.$set(this.attachTeachingMaterialsInfo, 'taskKey', this.popupParam.sopFireFightingTraningEduId)
          this.$set(this.attachTrainingPictureInfo, 'taskKey', this.popupParam.sopFireFightingTraningEduId)
          this.$set(this.attachEduPictureInfo, 'taskKey', this.popupParam.sopFireFightingTraningEduId)
        },);
      } else {
        // nothing
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>